AFRAME.registerComponent('marker-states', {
  schema: {
    states: {type: 'string'}
  },

  init: function() {
    this.states = JSON.parse(this.data.states);
    this.validateStates();
    this.currentState = null;

    this.sensor = this.el.components['marker-angle-sensor'];
    this.sensor.el.addEventListener('angle-found', (data) => this.onAngleFound(data));
    this.sensor.el.addEventListener('angle-lost', () => this.onAngleLost());

    this.el.emit('marker-states-ready', {markerStates: this});
  },

  validateStates: function() {
    for (const [state, angles] of Object.entries(this.states)) {
      if (angles.Min == null || angles.Max == null) {
        throw new Error(`Badly defined state at ${state}. Expected non-null "Min" and "Max" entries.`);
      }
    }
  },

  onAngleFound: function(data) {
    const angle = data.detail.angle;
    
    for (const [state, angles] of Object.entries(this.states)) {
      if (angle >= angles.Min && angle < angles.Max) {
        if (this.currentState == state) continue;

        this.updateState(state);
      }
    }
  },

  onAngleLost: function() {
    this.updateState(null);
  },

  updateState: function(newState) {
    const previousState = this.currentState;
    this.currentState = newState;
  
    this.el.emit('state-changed', { current: this.currentState, previous: previousState });
  },

  hasCurrentState: function() {
    return this.currentState != null;
  },

  getCurrentState: function() {
    return this.currentState;
  }
});
